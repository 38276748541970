<template>
  <LazyUiModal
    title="Заказать звонок"
    :open="appStore.modals.backCall"
    @close="appStore.modals.backCall = false"
  >
    <template v-if="!authStore.isLoggedIn">
      <p class="text-dnd_black text-base lg:text-center lg:text-lg">
        Вы можете заполнить форму - Вам перезвонят.
      </p>
      <form class="flex flex-col gap-6 py-3 lg:gap-10 lg:py-0">
        <div class="flex flex-col gap-3">
          <UiInput
            v-model="userData.name"
            class="lg:w-full lg:!min-w-0"
            data-maska="Z"
            data-maska-tokens="{'Z': { 'pattern': '[а-яА-Я-]', 'repeated': true } }"
            title="Ваше имя"
            :error="v$.name.$error"
          />
          <UiInput
            v-model="userData.phoneNumber"
            class="lg:w-full lg:!min-w-0"
            data-maska="+7 (9##) ### ##-##"
            title="Номер телефона"
            :error="v$.phoneNumber.$error"
          />
          <div class="flex max-lg:items-center">
            <UiCheckBox v-model="personalDataAgreement" />
            <p class="text-xs md:text-sm lg:text-base">
              Я даю своё согласие на
              <a
                class="text-dnd_black cursor-pointer italic underline"
                href="https://api.prostoyvopros.ru/v1/file/1716968442170_.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                обработку и передачу персональных данных
              </a>
            </p>
          </div>
        </div>
      </form>
    </template>
    <div
      v-if="authStore.isLoggedIn"
      class="text-center w-full flex flex-col gap-[4px]"
    >
      <p class="text-sm lg:text-lg">Позвоните нам</p>
      <a
        :href="`tel:${siteInfo.PHONE_NUMBER_NOT_FORMATTED}`"
        class="flex justify-center gap-[12px] lg:gap-[16px] items-center cursor-pointer"
      >
        <div class="rounded-full bg-dnd_yellow w-fit h-fit p-[8px] lg:p-[10px]">
          <UiIcon
            class="block lg:hidden"
            name="phone"
            size="xs"
          />
          <UiIcon
            class="hidden lg:block"
            name="phone"
            size="sm"
          />
        </div>
        <p class="text-dnd_black text-lg lg:text-xl font-bold">{{ siteInfo.PHONE_NUMBER }}</p>
      </a>
      <p class="text-sm lg:text-base">(звонок бесплатный)</p>

      <div class="flex w-full py-[4px]">
        <hr class="mx-[10px] flex-grow self-center" />
        <p class="text-dnd_dark_grey text-xs lg:text-sm">ИЛИ</p>
        <hr class="mx-[10px] flex-grow self-center" />
      </div>
    </div>
    <!-- <p class="text-dnd_dark_grey text-center text-[10px] lg:text-[14px]">
          Нажимая на кнопку «Продолжить», вы даете согласие на
          <span
            class="text-dnd_black cursor-pointer hover:underline"
            @click="togglePersonalDataAgreement"
          >
            обработку персональных данных
          </span>
          и ознакомлены с
          <span class="text-dnd_black cursor-pointer hover:underline">
            Политикой в отношении обработки персональных данных
          </span>
        </p> -->
    <template #button>
      <div class="w-full">
        <UiButton
          :loading="isLoading"
          @click="sendOrderBackCall"
        >
          {{ authStore.isLoggedIn ? 'Заказать обратный звонок' : 'Отправить' }}
        </UiButton>
      </div>
    </template>
  </LazyUiModal>
  <LazyUiModal
    title="Согласие на обработку и передачу персональных данных"
    z-index="1000"
    size="lg"
    scrollable
    :open="isPersonalDataAgreementModalVisible"
    @close="isPersonalDataAgreementModalVisible = false"
  >
    <template #default>
      <p class="text-justify text-base lg:text-lg">
        Я даю согласие ООО МКК «Юта» (191040, г. Санкт-Петербург, вн.тер.г. Муниципальный Округ
        Лиговка-ямская, пр-кт Лиговский, д. 74 литера А, помещ. 20Н (16-(офис №424)), ООО МКК
        «Рубикон» (660021, г. Красноярск, ул. Деповская, д. 15, помещ. 65), ООО МКК «Бриз» (6660077,
        г. Красноярск, ул. Авиаторов, дом 19, помещ. 31, комната №91), на обработку всех моих
        персональных данных, приведенных в заявке, и подтверждаю их принадлежность мне. Я даю
        согласие ООО МКК «Юта», ООО МКК «Рубикон», ООО МКК «Бриз», на обработку моих персональных
        данных любыми способами, в том числе воспроизведение, электронное копирование,
        обезличивание, блокирование, уничтожение, а также вышеуказанную обработку иных моих
        персональных данных, полученных в результате их обработки, с целью заключения договора займа
        с ООО МКК «Юта», ООО МКК «Рубикон», ООО МКК «Бриз», а также в любых других целях, прямо или
        косвенно связанных с предоставлением займа и предложением мне иных продуктов ООО МКК «Юта»,
        ООО МКК «Рубикон», ООО МКК «Бриз» и их партнеров.
      </p>
      <p class="text-justify text-base lg:text-lg">
        Я даю согласие ООО МКК «Юта», ООО МКК «Рубикон», ООО МКК «Бриз» на передачу моих
        персональных данных лицам, являющимся партнерами ООО МКК «Юта», ООО МКК «Рубикон», ООО МКК
        «Бриз», в том числе платежным системам, расчетным небанковским кредитным организациям,
        коллекторским агентствам и обработку персональных данных такими партнерами с целью выдачи
        мне займа, предоставления электронных средств платежа и исполнения обязательств по договору
        займа.
      </p>
    </template>
  </LazyUiModal>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import siteInfo from '~/helpers/siteInfo';
import { fio, phone } from '~/helpers/validator';

const { $api } = useNuxtApp();

const appStore = useAppStore();
const authStore = useAuthStore();
const userStore = useUserStore();
const notieStore = useNotieStore();
const dataForLongStore = useDataForLongStore();

const isPersonalDataAgreementModalVisible = ref(false);
const personalDataAgreement = ref(false);
const isLoading = ref(false);

const userData = reactive({
  phoneNumber: '',
  name: '',
});

// const togglePersonalDataAgreement = () => {
//   isPersonalDataAgreementModalVisible.value = !isPersonalDataAgreementModalVisible.value;
// };

const rules = computed(() => ({
  phoneNumber: { required, phone },
  name: { required, fio },
}));

const v$ = useVuelidate(rules, userData);
const sendOrderBackCall = async () => {
  const isLoggedIn = authStore.isLoggedIn;

  if (!isLoggedIn) {
    const isUserDataValid = await v$.value.$validate();

    if (!isUserDataValid) {
      notieStore.addNotification({
        type: 'error',
        message: 'Корректно заполните все поля',
      });
      return;
    }

    if (!personalDataAgreement.value) {
      notieStore.addNotification({
        type: 'warning',
        message: 'Необходимо принять пользовательское соглашение',
      });
      return;
    }
  }

  const authUser = userStore.user;
  const data = {
    summ: isLoggedIn
      ? (dataForLongStore.product_summ && dataForLongStore.product_summ.toString()) || '0'
      : '0',
    phone: isLoggedIn ? authUser.phone : userData.phoneNumber,
    region: 'Санкт-Петербург',
    city: 'Санкт-Петербург',
    email: 'noemail@mail.com',
    fio: isLoggedIn
      ? authUser.last_name + authUser.first_name + authUser.secondary_name
      : userData.name,
    uid: '1',
    offer: '4',
  };

  isLoading.value = true;
  const res = await $api.web().addLoanApplication(data);
  userData.phoneNumber = '';
  userData.name = '';
  appStore.modals.backCall = false;
  v$.value.$reset();
  notieStore.addNotification({
    type: 'success',
    message: res.data.message,
  });
  isLoading.value = false;
};
</script>
